/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';

import { motion, useAnimation, useInView } from 'framer-motion';

const AnimatedFooter = ({ children, value }) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, { threshold: 1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setIsVisible(true);
    } else {
      controls.start('hidden');
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <motion.div
      className="footer"
      ref={ref}
      initial={{ opacity: 0, y: value }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : value }}
      transition={{
        ease: 'easeOut',
        duration: 0.5,
        delay: isVisible ? 0.2 : 0,
        type: 'spring',
        damping: 20,
        stiffness: 100,
      }}
      style={{ marginBottom: '20px' }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedFooter;
