/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';

import { motion, useAnimation, useInView } from 'framer-motion';

const AnimatedImage = ({ src }) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const inView = useInView(ref, { threshold: 1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
      setIsVisible(true);
    } else {
      controls.start('hidden');
      setIsVisible(false);
    }
  }, [inView]);

  return (
    <motion.img
      className="comic"
      src={src}
      ref={ref}
      initial={{ opacity: 0, x: 150 }}
      animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : 150 }}
      transition={{
        ease: 'easeOut',
        duration: 0.5,
        delay: isVisible ? 0.2 : 0,
        type: 'spring',
        damping: 10,
        stiffness: 100,
      }}
      style={{ marginBottom: '20px' }}
      whileHover={{
        originX: 0.5,
        originY: 0.5,
        rotate: 1,
      }}
    ></motion.img>
  );
};

export default AnimatedImage;
